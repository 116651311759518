




















































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import Logo from '@/components/Logo.vue'

@Component({
  components: {
    LayoutCompact,
    Logo
  }
})
export default class MemberRegisterStatus extends Vue {
  public status: string = ''

  created() {
    if (this.$route.params.status) {
      this.status = this.$route.params.status
    } else {
      this.$router.push({ name: 'login' })
    }
  }

  public handleBackLogin() {
    localStorage.removeItem('items')
    localStorage.removeItem('store_id')
    this.$router.push({ name: 'login' })
  }

  public handleBackHome() {
    this.$router.push({ name: 'home' })
  }
}
